// Login.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginAndRequestOTP ,verifyOTP } from '../store/actions/authAction';
import { useAlert } from 'react-alert';
import { ERROR_CLEAR, SUCCESS_MESSAGE_CLEAR } from '../store/types/authType';

const Login = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, authenticate, error, successMessage } = useSelector(state => state.auth);
  const [state, setState] = useState({
    email: '',
    otp: '', 
    requestingOTP: false
  });

  const inputHandle = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const login = (e) => {
    e.preventDefault();
    if (!state.requestingOTP) {
      if (!state.email) {
        alert.error("Email is required");
        return;
      }
      dispatch(userLoginAndRequestOTP(state));
      setState({
        ...state,
        requestingOTP: true
      });
    } else {
      dispatch(verifyOTP(state));
    }
  }

  useEffect(() => {
    if (authenticate) {
      navigate('/');
    }
    if (successMessage) {
      alert.success(successMessage);
      dispatch({ type: SUCCESS_MESSAGE_CLEAR });
      if (successMessage !== 'verification code sent to your email') {
        setState({
          email: '',
          otp: '',
          requestingOTP: false
        });
      }
    }
    if (error) {
      error.map(err => alert.error(err));
      dispatch({ type: ERROR_CLEAR });
      if (error[0] === 'Your Email Not Found') {
        setState({ ...state, requestingOTP: false });
      }
    }
  }, [successMessage, error]);

  return (
    <div className='register'>
      <div className='_card'>
        <div className='card'>
          <img className='logo' src={require('../imagelog/midadBlue.png')} alt="Midad Logo" />
          <div className='card-header'>
            <h1>{state.requestingOTP ? 'Verify code' : 'Login'}</h1>
          </div>
          <div className='card-body'>
            <form onSubmit={login}>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input type="email"
                  onChange={inputHandle}
                  name="email" value={state.email}
                  className='form-control'
                  placeholder='Email' id='email'
                  disabled={state.requestingOTP}
                />
              </div>
              {state.requestingOTP && (
                <div className='form-group'>
                  <label htmlFor='otp'>Verification code</label>
                  <input type="text" onChange={inputHandle} name="otp" value={state.otp} className='form-control' placeholder='Verification code' id='otp' />
                </div>
              )}
              <div className='form-group'>
                <input type="submit" value={state.requestingOTP ? 'Verify' : 'Send code'} className='btn' />
              </div>
              <div className='form-group'>
                <span><Link to="/messenger/register"> Don't have an Account </Link></span>
              </div>
              {state.requestingOTP &&
                <div className='form-group'>
                  <span onClick={() => setState({ ...state, requestingOTP: false })}>Change email</span>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;