import React, { useState ,useEffect} from 'react';
import {updateProfile} from '../store/actions/messengerAction';
import { useDispatch } from 'react-redux';
const Profile = ({myInfo,setShowProfile}) => {
    const [user, setUser] = useState({
        image: null,
        username: '',
        email: ''
    });
    const formData = new FormData();
    const dispatch = useDispatch();
    useEffect(() => {
        setUser({
            ...user,
            username: myInfo.userName,
            email: myInfo.email
        });
    }, [myInfo]);
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            setUser({
                ...user,
                image: files[0] // Save the file object
            });
        } else {
            setUser({
                ...user,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to the server
        console.log('User data:', user);
        formData.append('username', user.username);
        formData.append('image', user.image);
        formData.append('email', user.email);
        dispatch(updateProfile(formData));
        setShowProfile(false);
    };

    return (
        <div className="profile-section">
            <div className="profile-hov">
                <div className="close" onClick={() => setShowProfile(false)}>
                <i className="material-symbols-outlined">Close</i>
                </div>
                <h2>Profile</h2>
                <form onSubmit={handleSubmit}>
                    <div className="profile-image">
                        {user.image ? (
                            <img src={URL.createObjectURL(user.image)} alt="Profile" />
                        ) : (
                            <img src={`./backend/images/${myInfo.image}`} alt="Profile" />
                        )
                    }
                        <label className="camera-icon" htmlFor="image">
                        <i className="material-symbols-outlined">Add_A_Photo</i>
                        </label>
                        <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleChange}
                            accept="image/*"
                        />
                    </div>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            name="username"
                            value={user.username}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={handleChange}
                            disabled
                        />
                        <div className="tooltip">Changing email isn't available yet.</div>
                    </div>
                    <button type="submit">Save</button>
                </form>
            </div>
        </div>
    );
};

export default Profile;
