import {
     FRIEND_GET_SUCCESS,
     MESSAGE_GET_SUCCESS,
     MESSAGE_SEND_SUCCESS,
     SOCKET_MESSAGE,
     UPDATE_FRIEND_MESSAGE,
     MESSAGE_SEND_SUCCESS_CLEAR,
     SEEN_MESSAGE,
     DELIVARED_MESSAGE,
     UPDATE,
     MESSAGE_GET_SUCCESS_CLEAR,
     SEEN_ALL,UPLOAD_AUDIO_SUCCESS, UPLOAD_AUDIO_FAILURE
   } from "../types/messengerType";
   
   const initialState = {
     users: [],
     friends: [],
     message: [],
     mesageSendSuccess: false,
     typing: false,
     themeMood: "",
     new_user_add: "",
     alert: "",
     publicKey: "",
     decrypted: "",
     type: "",
     audio: null,
     error: null,
   };
   
   
   export const messengerReducer = (state = initialState, action) => {
     const { type, payload } = action;
   
     switch (type) {
          case UPLOAD_AUDIO_SUCCESS:
            return {
              ...state,
              audio: payload.audio,
              error: null,
          };
      case UPLOAD_AUDIO_FAILURE:
          return {
              ...state,
              audio: null,
              error: payload.error,
          };
       case 'MESSAGE_GET':
         console.log('MESSAGE_GET', payload);
         const updatedFriends = state.friends.map(friend => {
           if (friend.fndInfo._id === payload.decrypted.senderId) {
             return {
               ...friend,
               msgInfo: payload.decrypted
             };
           }
           return friend;
         });
         return {
           ...state,
           friends: updatedFriends
         };
   
       case 'NEW_FRIEND_ADD':
         const updatedUsers = state.users.filter(user => user.fndInfo._id !== payload.friendId);
         return {
           ...state,
           users: updatedUsers,
           type: 'NEW_FRIEND_ADD'
         };
   
       case 'DELETE_MESSAGE':
         const updatedMessage = state.message.filter(m => m._id !== payload.decrypted._id);
         const updatedFriendsMsgInfo = state.friends.map(friend => {
           if (friend.fndInfo._id === payload.decrypted.senderId || friend.fndInfo._id === payload.decrypted.receiverId) {
             const lastMsg = updatedMessage.length > 0 ? updatedMessage[updatedMessage.length - 1] : null;
             return {
               ...friend,
               msgInfo: lastMsg
             };
           }
           return friend;
         });
         return {
           ...state,
           message: updatedMessage,
           friends: updatedFriendsMsgInfo
         };
   
       case 'THEME_GET_SUCCESS':
       case 'THEME_SET_SUCCESS':
         return {
           ...state,
           themeMood: payload.theme
         };
   
       case 'KEY_GENERATE_SUCCESS':
         return {
           ...state,
           publicKey: payload.publicKey
         };
   
       case 'DECRYPT_SUCCESS':
         return {
           ...state,
           decrypted: payload.decrypted,
           type: payload.type
         };
   
       case 'DECRYPT_CLEAR':
         return {
           ...state,
           decrypted: ''
         };
   
       case 'ALERT_CLEAR':
         return {
           ...state,
           alert: ''
         };
   
       case 'ALERT_SUCCESS':
       case 'ALERT_ERROR':
         return {
           ...state,
           alert: payload
         };
   
       case 'GET_USERS_SUCCESS':
         return {
           ...state,
           users: payload.users
         };
   
       case 'ADD_FRIEND_SUCCESS':
         const updatedUsersForFriendAdd = state.users.filter(user => user.fndInfo._id !== payload.friend.fndInfo._id);
         return {
           ...state,
           users: updatedUsersForFriendAdd,
           friends: [...state.friends, payload.friend]
         };
   
       case 'MESSAGE_DELETE_SUCCESS':
         const updatedMsgForDelete = state.message.filter(m => m._id !== payload.msg._id);
         const updatedFriendsForMsgDelete = state.friends.map(friend => {
           if (friend.fndInfo._id === payload.msg.senderId || friend.fndInfo._id === payload.msg.receiverId) {
             const lastMsg = updatedMsgForDelete.length > 0 ? updatedMsgForDelete[updatedMsgForDelete.length - 1] : null;
             return {
               ...friend,
               msgInfo: lastMsg
             };
           }
           return friend;
         });
         return {
           ...state,
           message: updatedMsgForDelete,
           friends: updatedFriendsForMsgDelete
         };
   
       case 'MESSAGE_WITH_ID':
         const msgToUpdateIndex = state.message.findIndex(m => m.message.text === payload.message.text);
         if (msgToUpdateIndex !== -1) {
           const updatedMessageText = [...state.message];
           updatedMessageText[msgToUpdateIndex].message.text = payload.newMsg;
           return {
             ...state,
             message: updatedMessageText
           };
         }
         return state;
   
       case 'MESSAGE_UPDATE_SUCCESS':
         if (payload.decrypted) {
           const msgToUpdateIndex = state.message.findIndex(m => m._id === payload.decrypted._id);
           if (msgToUpdateIndex !== -1) {
             const updatedMessageText = [...state.message];
             updatedMessageText[msgToUpdateIndex].message.text = payload.decrypted.message.text;
             return {
               ...state,
               message: updatedMessageText
             };
           } else {
             const friendIndex = state.friends.findIndex(f => f.msgInfo.senderId === payload.decrypted.senderId || f.msgInfo.receiverId === payload.decrypted.receiverId);
             if (friendIndex !== -1) {
               const updatedFriendMsgText = [...state.friends];
               updatedFriendMsgText[friendIndex].msgInfo.message.text = payload.decrypted.message.text;
               return {
                 ...state,
                 friends: updatedFriendMsgText
               };
             }
           }
         } else {
           const msgToUpdateIndex = state.message.findIndex(m => m._id === payload.msgId);
           if (msgToUpdateIndex !== -1) {
             const updatedMessageText = [...state.message];
             updatedMessageText[msgToUpdateIndex].message.text = payload.newMsg;
             const friendIndex = state.friends.findIndex(f => f.fndInfo._id === payload.senderId || f.fndInfo._id === payload.receiverId);
             if (friendIndex !== -1) {
               const updatedFriendMsgInfo = [...state.friends];
               updatedFriendMsgInfo[friendIndex].msgInfo = updatedMessageText[msgToUpdateIndex];
               return {
                 ...state,
                 message: updatedMessageText,
                 friends: updatedFriendMsgInfo
               };
             }
             return {
               ...state,
               message: updatedMessageText
             };
           }
         }
         return state;
   
       case FRIEND_GET_SUCCESS:
         return {
           ...state,
           friends: payload.friends
         };
   
       case MESSAGE_GET_SUCCESS:
         return {
           ...state,
           message_get_success: true,
           message: payload.message,
           audio: payload.audio,
           document: payload.document,

         };
   
       case MESSAGE_SEND_SUCCESS:
         if (payload.forwarded) {
           const friendIndex = state.friends.findIndex(f => f.fndInfo._id === payload.message.receiverId || f.fndInfo._id === payload.message.senderId);
           if (friendIndex !== -1) {
             const updatedFriendsForSendSuccess = [...state.friends];
             updatedFriendsForSendSuccess[friendIndex].msgInfo = payload.message;
             return {
               ...state,
               mesageSendSuccess: true,
               message: [...state.message, payload.message],
               friends: updatedFriendsForSendSuccess,
             };
           }
         }
         return {
           ...state,
           mesageSendSuccess: true,
           message: [...state.message, payload.message],
         };
   
       case SOCKET_MESSAGE:
         return {
           ...state,
           message: [...state.message, payload.message]
         };
   
       case UPDATE_FRIEND_MESSAGE:
         const friendIndexToUpdate = state.friends.findIndex(f => f.fndInfo._id === payload.msgInfo.receiverId || f.fndInfo._id === payload.msgInfo.senderId);
         if (friendIndexToUpdate !== -1) {
           const updatedFriendsForMessageUpdate = [...state.friends];
           updatedFriendsForMessageUpdate[friendIndexToUpdate].msgInfo = payload.msgInfo;
           updatedFriendsForMessageUpdate[friendIndexToUpdate].msgInfo.status = payload.status;
           return {
             ...state,
             friends: updatedFriendsForMessageUpdate
           };
         }
         return state;
   
       case MESSAGE_SEND_SUCCESS_CLEAR:
         return {
           ...state,
           mesageSendSuccess: false
         };
   
       case SEEN_MESSAGE:
         const friendIndexForSeen = state.friends.findIndex(f => f.fndInfo._id === payload.msgInfo.receiverId || f.fndInfo._id === payload.msgInfo.senderId);
         if (friendIndexForSeen !== -1) {
           const updatedFriendsForSeen = [...state.friends];
           updatedFriendsForSeen[friendIndexForSeen].msgInfo.status = 'seen';
           return {
             ...state,
             friends: updatedFriendsForSeen
           };
         }
         return state;
   
       case DELIVARED_MESSAGE:
         const friendIndexForDelivered = state.friends.findIndex(f => f.fndInfo._id === payload.decrypted.receiverId || f.fndInfo._id === payload.decrypted.senderId);
         if (friendIndexForDelivered !== -1) {
           const updatedFriendsForDelivered = [...state.friends];
           updatedFriendsForDelivered[friendIndexForDelivered].msgInfo.status = 'delivered';
           console.log('DELIVARED_MESSAGE', updatedFriendsForDelivered);
           return {
             ...state,
             friends: updatedFriendsForDelivered
           };
         }
         return state;
   
       case 'USER_DISCONNECTED':
         const friendIndexForDisconnected = state.friends.findIndex(f => f.fndInfo._id === payload.friendId);
         if (friendIndexForDisconnected !== -1) {
           const updatedFriendsForDisconnected = [...state.friends];
           updatedFriendsForDisconnected[friendIndexForDisconnected].lastSeen = new Date();
           return {
             ...state,
             friends: updatedFriendsForDisconnected
           };
         }
         return state;
   
       case UPDATE:
         const friendIndexForUpdate = state.friends.findIndex(f => f.fndInfo._id === payload.id);
         if (friendIndexForUpdate !== -1 && state.friends[friendIndexForUpdate].msgInfo) {
           const updatedFriendsForUpdate = [...state.friends];
           updatedFriendsForUpdate[friendIndexForUpdate].msgInfo.status = 'seen';
           return {
             ...state,
             friends: updatedFriendsForUpdate
           };
         }
         return state;
   
       case MESSAGE_GET_SUCCESS_CLEAR:
         return {
           ...state,
           message_get_success: false
         };
   
       case SEEN_ALL:
         const friendIndexForSeenAll = state.friends.findIndex(f => f.fndInfo._id === payload.decrypted.receiverId);
         if (friendIndexForSeenAll !== -1) {
           const updatedFriendsForSeenAll = [...state.friends];
           updatedFriendsForSeenAll[friendIndexForSeenAll].msgInfo.status = 'seen';
           return {
             ...state,
             friends: updatedFriendsForSeenAll
           };
         }
         return state;
   
       case 'LOGOUT_SUCCESS':
         return {
           ...state,
           friends: [],
           message: [],
           mesageSendSuccess: false,
           message_get_success: false
         };
   
       case 'TYPING_MESSAGE':
         return {
           ...state,
           typing: true
         };
   
       case 'TYPING_CLEAR':
         return {
           ...state,
           typing: false
         };
   
       case 'NEW_USER_ADD':
         return {
           ...state,
           new_user_add: payload
         };
   
       case 'NEW_USER_ADD_CLEAR':
         return {
           ...state,
           new_user_add: ''
         };
   
       default:
         return state;
     }
   };
   