import React, { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { decryptData } from '../store/actions/messengerAction';
const Vacall = ({ currentfriend, activeUser, myInfo, socket, videoOrAudio,
   setShowCall, showCall, setInCall ,caller,setCaller,peerI,localVideoRef,
   remoteVideoRef,acceptCall,setAcceptCall,inCall,
   callDuration,setCallDuration,callTimerRef,startCallTimer,decrypted }) => {

  const [muting, setMuting] = useState(true);
  const [videoOff, setVideoOff] = useState(false);
  const [secondCall, setSecondCall] = useState(false);
  const peerRef = useRef(null);
  const [otherVideoOff, setOtherVideoOff] = useState(false);
  const [otherUserMuted, setOtherUserMuted] = useState(false);
  const [calling, setCalling] = useState(false); // State to track if the caller is currently calling
  const dispatch = useDispatch();
  useEffect(() => {
      peerRef.current = peerI;
      navigator.mediaDevices.getUserMedia({ video: videoOrAudio, audio: true })
          .then((stream) => {
              if (localVideoRef.current) {
                  localVideoRef.current.srcObject = stream;
              }
          })
          .catch((error) => {
              console.error('Error accessing media devices:', error);
          });


          return () => {
            clearInterval(callTimerRef.current); // Clear the call timer interval on unmount
          };
  }, [videoOrAudio, localVideoRef, peerI]);

  const handleCall = () => {
    setCalling(true);
    socket.current.emit('notifyCall', currentfriend, myInfo);
  };

  useEffect(() => {
    const handleCallResponse = (encryptedData) => {
      dispatch(decryptData(encryptedData,myInfo.id,'CALL_RESPONSE'));
    };
  
    socket.current.on('callResponse', handleCallResponse);
  
    return () => {
      socket.current.off('callResponse', handleCallResponse);
    };
  }, []);

  useEffect (() => {
    if (decrypted.response === 'accept') {
      setInCall(true);
      const call = peerRef.current.call(currentfriend._id, localVideoRef.current.srcObject);
      call.on('stream', (remoteStream) => {

        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = remoteStream;
        }
      });
      startCallTimer(); // Start the call timer when the call is accepted
      setCalling(false);
    } else if (decrypted.response === 'reject') {
      toast.error(`${currentfriend.userName} rejected the call`);
      setShowCall(false);
      handleHangup('caller')();
      setCalling(false);
    } else if (decrypted.response === 'timeout') {
      toast.error('Call request timed out');
      setCalling(false);
    }
  }, [decrypted]);


let varToTriggerUseEffect = 0;
  useEffect(() => {
    setCallDuration(0);
  }, [varToTriggerUseEffect]);
  const handleHangup = (caller) => () => {
    if (inCall) {
    socket.current.emit('notifyHangup', currentfriend, myInfo);
    }
    console.log('hangup');
    //set the call duration to 0
    varToTriggerUseEffect++;
    console.log(callDuration);
    if (localVideoRef.current && localVideoRef.current.srcObject) {
        const tracks = localVideoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
    }

    if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
        const tracks = remoteVideoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
    }

    if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
    }
    if (localVideoRef.current) {
        localVideoRef.current.srcObject = null;
    }
    setShowCall(false);
    setInCall(false);
    setSecondCall(false);
};

  // Handle remote video off notification
  useEffect(() => {
    console.log('notifyVideoOff');
    const handleVideoOff = () => {
      setOtherVideoOff(true);
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
      }
    };
  
    socket.current.on('notifyVideoOff', handleVideoOff);
  
    return () => {
      socket.current.off('notifyVideoOff', handleVideoOff);
    };
  }, [remoteVideoRef]);
// Handle remote video on notification
useEffect(() => {
  console.log('notifyVideoOn')
  const handleVideoOn = () => {
    setOtherVideoOff(false);
    if (peerRef.current) {
      let call;
      // Check if local video stream is available
      if (localVideoRef.current && localVideoRef.current.srcObject) {
        call = peerRef.current.call(currentfriend._id, localVideoRef.current.srcObject);
      } else {
        // Retrieve the user's video stream again
        navigator.mediaDevices.getUserMedia({ video: videoOrAudio, audio: true })
          .then((stream) => {
            if (localVideoRef.current) {
              localVideoRef.current.srcObject = stream;
              // Make the call with the new video stream
              call = peerRef.current.call(currentfriend._id, localVideoRef.current.srcObject);
            }
          })
          .catch((error) => {
            console.error('Error accessing media devices:', error);
          });
      }
  
      if (call) {
        call.on('stream', (remoteStream) => {
          if (remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = remoteStream;
          }
        });
      }
    }
  };
  

  socket.current.on('notifyVideoOn', handleVideoOn);

  return () => {
    socket.current.off('notifyVideoOn', handleVideoOn);
  };
}, [remoteVideoRef, localVideoRef, currentfriend, peerRef]);

useEffect(() => {
  console.log('video of',videoOff);
  if (videoOff) {
    // Stop the video stream locally
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      const tracks = localVideoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
    // Notify the other user that video is off
    socket.current.emit('notifyVideoOff', currentfriend);
  } else {
    // Start the video stream locally
    navigator.mediaDevices.getUserMedia({ video: videoOrAudio, audio: true })
      .then((stream) => {
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
        // Notify the other user that video is on
        socket.current.emit('notifyVideoOn', currentfriend);
      })
      .catch((error) => {
        console.error('Error accessing media devices:', error);
      });
  }
},[videoOff]);
useEffect(() => {
  const handleMuteToggleNotification = (isMuted) => {
    setOtherUserMuted(isMuted); // Update state to reflect whether the other user is muted or not
  };

  socket.current.on('notifyMuteToggle', handleMuteToggleNotification);

  return () => {
    socket.current.off('notifyMuteToggle', handleMuteToggleNotification);
  };
}, []);

const handleMuteToggle = () => {
  setMuting(!muting); // Toggle the muting state

  // Mute or unmute the audio tracks based on the muting state
  if (localVideoRef.current && localVideoRef.current.srcObject) {
    const audioTracks = localVideoRef.current.srcObject.getAudioTracks();
    audioTracks.forEach(track => {
      track.enabled = !muting;
    });
  }
  socket.current.emit('notifyMuteToggle', currentfriend, !muting);
};

const formatCallDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
  return (
    <div className='container-big video'>
      <div className='remote-side'>
          {inCall ? (
            <div className='in-call'>
              {!otherVideoOff ? (
              <video id='remoteVideo' autoPlay playsInline ref={remoteVideoRef} />
            ) : (
              <div className='if-remote-video-off' >
                <img src = {`./backend/images/${currentfriend.image}`} alt='' />
                <h3>{currentfriend.userName}</h3>
              </div>
            )
            }
              <div className='buttons'>
              <div className='video-off' onClick={() => setVideoOff(!videoOff)}>
                  < i className='material-symbols-outlined'> {videoOff ? 'Hangout_Video_Off' : 'Videocam'} </i>
                </div>
                <div className='hangup' onClick={handleHangup('caller')}>
                  < i className='material-symbols-outlined'>Call_End</i>
                </div>
                <div className='mute' onClick={() => handleMuteToggle()}>
                  < i className='material-symbols-outlined'>{muting ? 'Mic' : 'Mic_Off'}</i>
                </div>
              </div>
          </div>

          ) :(
            <div className='call-form'>
              <div className='call-form-header not-in-call'>
                <img src={`./backend/images/${currentfriend.image}`} alt='' />
                <h3>{currentfriend.userName}</h3>
              </div>
            <div className='call-form-body'>
              {calling && <>
              <p>Calling...</p>
              <div className='hangup' onClick={handleHangup('caller')}>
                < i className='material-symbols-outlined'>Close</i>
              </div>
              </>}
                {!calling && !inCall &&
                <div className='has-not-called'>
                  <div className='call' onClick={handleCall} >
                    < i className='material-symbols-outlined'>Videocam</i>
                  </div>
                  <div className='cancel' onClick={handleHangup('called')}>
                    < i className='material-symbols-outlined'>Close</i>
                  </div>
                </div>
                }
            </div>
            </div>
          )
        }
</div>
        <div className='local-side'>
          <div className='timer'>
            <div className='time-text' >
            <p>{formatCallDuration(callDuration)}</p>
            </div>
          </div>
          <div className='local-video'>
            { !videoOff ? (
            <video id='localVideo' autoPlay playsInline muted ref={localVideoRef} />
            ) : (
              <div className='if-local-video-off' >
                <img src = {`./backend/images/${myInfo.image}`} alt='' />
              </div>
            )
            }
          </div>
        </div>
    </div>
  )
}

export default Vacall;