import React from 'react';
import { FaCaretSquareDown,FaEdit,FaSistrix } from "react-icons/fa";

const FriendInfo = ({currentfriend,activeUser,message,handleSearch,searchTerm}) => {
  return (
       <div className='friend-info'>
            <input type="checkbox" id='gallery' />
            <div className='image-name'>
                 <div className='image'>
                 <img src={`./backend/images/${currentfriend.image}`} alt='' />
                 </div>
                 {
           activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === currentfriend._id) ? <div className='active-user'>Active</div> : ''
      }

     <div className='name'>
          <h4>{currentfriend.userName} </h4>
     </div>
            </div>


     <div className='others'>

     <div className='media'>
          <h3>Shared Media </h3>
        <label htmlFor='gallery'> <FaCaretSquareDown/> </label> 
     </div>
     </div>
 
     <div className='gallery'>
     {
          message && message.length>0 ? message.map((m,index)=>m.message.image && <img key={index} src={`./backend/images/${m.message.image}`} />) : ''
     }
     </div>
     <div className="message-search">
          <input
              type="text"
              placeholder="Search messages..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
          />
      </div>
      <div className="search-note">
          Start typing and unrelated messages will disappear
        </div>

       </div>
  )
};

export default FriendInfo;
