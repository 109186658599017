import axios from 'axios';
import {REGISTER_FAIL,REGISTER_SUCCESS,
    USER_LOGIN_SUCCESS,USER_LOGIN_FAIL,
    LOGOUT_SUCCESS,OTP_VERIFY_SUCCESS,
    OTP_VERIFY_FAIL} from '../types/authType';
//prod
const API_BASE_URL ="https://midad.tn/backend";
//dev
//const API_BASE_URL ="http://localhost:5000/backend";

export const userRegister = (data) => {
    console.log(data);
     return async (dispatch) => {

          const config = {
               headers: {
                    'Content-Type': 'multipart/form-data'
               }
          }
          try{
               const response = await axios.post(`${API_BASE_URL}/api/auth/user-register`,data,config);
            console.log(response);
               dispatch({
                    type : REGISTER_SUCCESS,
                    payload:{
                         successMessage: response.data.successMessage
                    }
               })
          } catch(error){
                dispatch({
                    type: REGISTER_FAIL,
                    payload:{
                         error : error.response.data.error.errorMessage
                    }
                })
          }

     }
}

export const userLoginAndRequestOTP = (data) => {
    return async (dispatch) => {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/user-login`, data, config);
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.successMessage,
                }
            })
        }catch (error) {
            console.log(error);
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: {
                    error: error.response.data.error.errorMessage
                }
            })
        }
    }
}


export const verifyOTP = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/verifyOTP`, data, config);
            localStorage.setItem('authToken',response.data.token);
            dispatch({
                type: OTP_VERIFY_SUCCESS,
                payload: {
                    successMessage: response.data.successMessage,
                    token: response.data.token
                }
            })
        } catch (error) {
            dispatch({
                type: OTP_VERIFY_FAIL,
                payload: {
                    error: error.response.data.error.errorMessage
                }
            })
        }
    }
}

const withAuthHeaderConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
};

export const userLogout = () => async(dispatch) => {
    const token = localStorage.getItem('authToken');
     try{
         const response = await axios.post(`${API_BASE_URL}/api/auth/user-logout`,{},withAuthHeaderConfig);

         if(response.data.successMessage){
             localStorage.removeItem('authToken');
             dispatch({
                 type : LOGOUT_SUCCESS,
             })
         }

     }catch (error) {
        console.error(error);

     }
}




