import React from 'react';

const ActiveFriend = ({user, handleClickActiveFriend}) => {
    const handleOnClick = () => {
        handleClickActiveFriend(user);
    }
    return (
        <div onClick={
            handleOnClick
        } className='active-friend'>
            <div className='image-active-icon'>
                <div className='image'>
                    <img src={`./backend/images/${user.userInfo.image}`} alt=''/>
                    <div className='active-icon'></div>
                </div>

            </div>

        </div>
    )
};

export default ActiveFriend;
